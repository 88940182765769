@import (reference) "../settings.less";

@media (max-width: 1279px) {
    .user-orders-list .order-header span {
        padding-left: 24px;

        i {
            width: 16px;
            height: 16px;
        }
    }

    .field-wrapper:not(.checking-reg) label {
        left: 10px;
    }

    .field-wrapper:not(.checking-reg).icon input {
        padding-left: 40px;
    }

    .field-wrapper:not(.checking-reg).icon:before {
        width: 40px;
    }

    .field-wrapper:not(.checking-reg).icon label {
        left: 36px;
    }

    .faq-list > li > span {
        font-size: 15px;
    }

    // CART
    .single-saved-cart .buttons-wrapper {
        height: auto;

        a, i {
            padding: 3px 10px;
            font-size: 12px;
        }
    }

    .cart-options-buttons img {
        height: 31px;
    }

    // OTHER
    .login-form.logged .logged-menu {
        flex-wrap: wrap;

        li {
            width: 100%;
            border-right: 0;

            &:not(:last-child) {
                border-bottom: 1px solid @border-color;
            }
        }

        a {

            svg {
                bottom: auto;
                top: 50%;
                transform: translateY(-50%) !important;
                height: 50%;
                left: 20px;
            }
        }
    }

    .login-form.logged .logged-header h2 {
        padding-left: 0;
        overflow: hidden;
        max-width: 100%;

        &:before {
            display: none;
        }
    }

    .login-form.logged .logged-header ul {
        width: 100%;

        li {
            text-align: left;
        }

        a {
            gap: 10px;
        }

        a:before {
            order: 1;
            margin: 0;
            position: relative;
            top: -1px;
        }
    }
}

@media (max-width: 1100px) {
    // CART
    .check-person {
        margin-top: 20px;
    }

    .payment-channels-modal .goshop-modal .wrapper {
        gap: 10px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .cart-final-boxes {
        display: flex;
        flex-direction: column;
    }

    .checkbox-inline {
        padding: 0 0 15px;
    }

    .checkbox-wrapper {
        padding: 10px;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all {
        margin-bottom: 0;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all ~ * {
        padding-left: 10px;
    }

    .fields-required-text {
        padding-top: 5px;
    }

    .oauth-signup {
        gap: 10px;
    }

    .oauth-signup > span {
        padding: 20px 0 0;
    }

    .oauth-signup > a.confirm-button {
        font-size: 0;
        padding: 0;
        width: 50px;
        min-width: 0;

        svg {
            margin: 0 !important;
        }
    }

    .field-wrapper:not(.checking-reg),
    .to-show .field-wrapper:last-of-type {
        margin-bottom: 15px !important;
    }

    .order-detailed-info .item-box-small,
    .order-detailed-info .item-box-medium {
        flex-basis: 100%;
        border-right: 0;
    }
}

@media (max-width: 600px) {
    .goshop-modal-body {
        padding: 10px;
    }

    .payment-channels-modal .goshop-modal-body .wrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .order-buttons-wrapper .wrapper {

        > * {
            flex-grow: 1;

            .return-button {
                width: 100%;
            }
        }
    }

    .checkout-tab li {

        &.active span {
            position: relative;
            top: 1px;
        }
    }

    .cart-promo-code {
        width: 100%;
    }

    .cart-header {
        gap: 10px;

        .logo {
            flex-shrink: 1;
            max-width: 40%;

            img {
                height: auto;
                width: auto;
                max-height: 100%;
                max-width: 100%;
            }
        }

        .cart-back-link {
            white-space: nowrap;
            flex-shrink: 0;
        }
    }

    .check-person {
        gap: 10px;
    }

    form .row-wrapper.address-wrapper,
    form .row-wrapper.city-wrapper {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .address-select-list {
        width: ~"calc(100vw - 42px)";
        overflow-x: auto;
        flex-wrap: nowrap;
        padding-top: 10px;
    }

    .goshop-modal label {
        margin-bottom: 15px;
    }

    .goshop-modal .captcha-wrapper {

        > div {
            width: 268px !important;
            height: 68px !important;
            overflow: hidden;

            iframe {
                transform-origin: left top;
                transform: scale(.87);
                outline: 0 !important;
            }
        }
    }

    .address-select-list li:not(.no-addresses) {
        max-width: 100%;
        min-width: 75%;
    }

    .shop-buttons-wrapper {
        gap: 10px;

        > * {
            width: 100%;
            text-align: center;

            &:nth-child(2) {
                order: -1;
            }
        }
    }

    .check-person {

        > label {
            width: 100%;
        }

        .check-simple:first-of-type {
            margin-left: 0;
        }
    }

    .login-form > .signup-wrapper {
        width: 100%;
        padding-right: 0;
        border-right: 0;
    }

    .login-form > .signup-wrapper:nth-child(2) {
        padding-left: 0;
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid @border-color;
    }

    .user-orders-list .order-header .buttons-wrapper {
        position: static;
        margin-top: 10px;
        width: 100%;
        flex-direction: row;

        a {
            padding: 5px;
        }
    }

    .order-detailed-info .item-box-large th:nth-child(2),
    .order-detailed-info .item-box-large td:nth-child(2) {
        display: none;
    }

    .login-form.logged .logged-header h2 {
        font-size: 18px;
    }

    .thread-messages .message {
        margin-bottom: 20px;
    }

    .thread-messages .message header {
        flex-wrap: wrap;
        gap: 5px;
    }

    .thread-messages .message header .message-name {
        font-size: 14px;
    }

    .message-time {
        width: 100%;
        margin-top: -5px;
    }

    .thread-messages .message.client-response header .message-time {
        text-align: right;
    }

    .payment-channels-modal .goshop-modal .wrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .user-orders-list .order-header {
        display: flex;
        flex-direction: column;

        .buttons-wrapper {
            position: static;
            height: auto;
            flex-direction: row;
        }
    }

    .user-orders-summary li strong {
        display: block;
    }
}